import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export const toastSuccess = text => {
  Vue.$toast(
    {
      component: ToastificationContent,
      props: {
        title: 'Berhasil',
        text,
        variant: 'success',
        icon: 'CheckIcon',
      },
    },
    { timeout: 2500 },
  )
}

export const toastError = text => {
  Vue.$toast(
    {
      component: ToastificationContent,
      props: {
        title: 'Gagal',
        text,
        variant: 'danger',
        icon: 'AlertCircleIcon',
      },
    },
    { timeout: 2500 },
  )
}

export const columnsTableEvent = [
  {
    key: 'no',
    label: 'No',
    class: 'p-1 text-black',
    thStyle: {
      fontSize: '14px',
      color: 'black',
      textTransform: 'capitalize',
    },
  },
  {
    key: 'title_event',
    label: 'Judul Event',
    class: 'p-1 text-black',
    thStyle: {
      fontSize: '14px',
      color: 'black',
      textTransform: 'capitalize',
      minWidth: '230px',
    },
  },
  {
    key: 'status_event',
    label: 'Status',
    class: 'p-1 text-black',
    thStyle: {
      fontSize: '14px',
      color: 'black',
      textTransform: 'capitalize',
    },
  },
  {
    key: 'type_event',
    label: 'Type Event',
    class: 'p-1 text-black',
    thStyle: {
      fontSize: '14px',
      color: 'black',
      textTransform: 'capitalize',
      minWidth: '150px',
    },
  },
  {
    key: 'total_participant',
    label: 'Jumlah Peserta',
    class: 'p-1 text-black',
    thStyle: {
      fontSize: '14px',
      color: 'black',
      textTransform: 'capitalize',
      minWidth: '150px',
    },
  },
  {
    key: 'time',
    label: 'Waktu',
    class: 'p-1 text-black',
    thStyle: {
      fontSize: '14px',
      color: 'black',
      textTransform: 'capitalize',
      minWidth: '200px',
    },
  },
  {
    key: 'action',
    label: 'Aksi',
    class: 'p-1 text-black',
    thStyle: {
      fontSize: '14px',
      color: 'black',
      textTransform: 'capitalize',
      minWidth: '100px',
    },
  },
]

export const columnsTableParticipant = [
  {
    key: 'no',
    label: 'No',
    class: 'p-1 text-black',
    thStyle: {
      fontSize: '14px',
      color: 'black',
      textTransform: 'capitalize',
    },
  },
  // {
  //   key: 'email',
  //   label: 'Email',
  //   class: 'p-1 text-black',
  //   thStyle: {
  //   //   fontSize: '14px',
  //     color: 'black',
  //   },
  // },
  {
    key: 'name',
    label: 'Nama',
    class: 'p-1 text-black',
    thStyle: {
      fontSize: '14px',
      color: 'black',
      textTransform: 'capitalize',
    },
  },
  {
    key: 'handphone',
    label: 'No Hp',
    class: 'p-1 text-black',
    thStyle: {
      fontSize: '14px',
      color: 'black',
      textTransform: 'capitalize',
    },
  },
  {
    key: 'city',
    label: 'Kota',
    class: 'p-1 text-black',
    thStyle: {
      fontSize: '14px',
      color: 'black',
      textTransform: 'capitalize',
    },
  },
  {
    key: 'product',
    label: 'Product',
    class: 'p-1 text-black',
    thStyle: {
      fontSize: '14px',
      color: 'black',
      justifyItems: 'center',
      justifyContent: 'center',
      textTransform: 'capitalize',
    },
  },
  {
    key: 'action',
    label: 'Action',
    class: 'p-1 text-black',
    thStyle: {
      fontSize: '14px',
      color: 'black',
      textTransform: 'capitalize',
    },
  },
]

export function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text
  }
  return `${text.slice(0, maxLength)}...`
}
