<template>
  <BCard>
    <div class="text-black text-[20px] mb-2 font-[600]">
      Dashboard Open Api
    </div>
    <div class="d-flex justify-content-end">
      <!-- filter -->
      <b-form-group class="mb-0 text-end d-flex flex-row align-items-center">
        <div class="d-flex gap-5">
          <b-input-group
            class="input-group-merge"
            size="md"
          >
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              id="filterInput"
              v-model="search"
              :maxlength="32"
              :minlength="2"
              type="search"
              placeholder="Search Collaborator"
              debounce="500"
              @input="onChangeSearch"
            />
          </b-input-group>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="md"
            @click="showFilter"
          >
            <feather-icon icon="FilterIcon" />
          </b-button>
        </div>
      </b-form-group>
      <ul
        v-if="isFilterShow"
        class="d-block position-absolute bg-[#f9f9f9] z-10 text-black font-medium text-[12px] pb-1 mt-5 shadow-lg border-none rounded"
      >
        <div class="text-black pl-2 py-1 text-[16px] border">
          Filter
        </div>
        <div class="hover:bg-[#FCD4BE]">
          <button
            id="popoverStatusTicket"
            class="btn mb-1 d-flex"
          >
            <span>Access API Type</span>
          </button>
          <b-popover
            target="popoverStatusTicket"
            triggers="hover"
            placement="left"
          >
            <div
              v-for="(items, index) in apiAccessTypeList"
              :key="index + 1"
            >
              <b-form-checkbox
                v-model="items.onCheck"
                class="custom-control-primary mb-1"
                @change="filterTicketByStatus(items)"
              >
                <span class="text-black">
                  {{ items.label }}
                </span>
              </b-form-checkbox>
            </div>
          </b-popover>
        </div>
        <hr>
        <div class="hover:bg-[#FCD4BE]">
          <date-range-picker
            ref="picker"
            v-model="dateRange"
            :locale-data="locale"
            :ranges="ranges"
            :opens="'left'"
            control-container-class="border-0"
          >
            <template v-slot:input="picker">
              <div class="d-flex btn">
                <span>Date Created</span>
              </div>
              <span class="d-none">{{ picker }}</span>
            </template>
          </date-range-picker>
        </div>
      </ul>
    </div>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <BTable
        id="scroll"
        ref="table-scroll"
        :items="listCollaborator"
        :fields="fieldsCollaboratorTable"
        show-empty
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        sticky-header="500px"
        class="mt-1"
        @scroll.native="handleScroll"
      >
        <template #cell(name)="data">
          <div
            style="min-width: 20px !important"
            class="d-flex flex-column"
          >
            {{ data.item.name }}
            <small class="text-secondary">{{ data.item.email }}</small>
          </div>
        </template>
        <template #cell(package)="data">
          <img
            v-if="data.item.package"
            :src="handleImagePackage(data.item.package)"
            alt="package"
          >
        </template>
        <template #cell(phone_number)="data">
          <div class="d-flex align-items-center gap-5">
            {{ data.item.phone_number }}
            <BButton
              class="p-0 justify-between items-center"
              type="button"
              aria-expanded="false"
              variant="light"
              style="width: 20px !important;"
              @click="checkWA(data.item.phone_number)"
            >
              <img
                v-if="data.item.phone_number"
                src="https://storage.googleapis.com/komerce//whatsapp-ori.svg"
                alt="package"
              >
            </BButton>
          </div>
        </template>
        <template #cell(api_access)="data">
          <div class="d-flex align-items-center gap-5">
            <div
              v-if="data.item.is_sandbox"
              class="d-flex align-items-center justify-content-center gap-5 sandbox px-[10px] py-[5px]"
            >
              Sandbox
            </div>
            <div
              v-if="data.item.is_production"
              class="d-flex align-items-center justify-content-center gap-5 live px-[10px] py-[5px]"
            >
              Live
            </div>
          </div>
        </template>
        <template #cell(detail)="data">
          <div class="d-flex align-items-center gap-5 detail">
            <span
              class="cursor-pointer"
              @click="navigateToDetail(data.item.email)"
            >Detail</span>
          </div>
        </template>
      </BTable>
    </BOverlay>
  </BCard>
</template>
<script>
import { openApiAxiosIns, komshipAxiosIns } from '@/libs/axios'
import { LABELDAY, LABELMONTH, YEAR_MONTH_DAY } from '@/libs/filterDate'
import moment from 'moment'
import {
  today,
  last7,
  last2,
  last30,
  last60,
  firstDateOfMonth,
  lastDateOfMonth,
  lastMonth,
  lastDateOfLastMonth,
} from '@/store/helpers'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import { fieldsCollaboratorTable } from './config'

export default {
  components: { DateRangePicker },
  data() {
    return {
      loading: false,
      fieldsCollaboratorTable,
      search: '',
      isFilterShow: false,
      YEAR_MONTH_DAY,
      picker: {
        startDate: firstDateOfMonth,
        endDate: lastDateOfMonth,
      },
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: LABELDAY,
        monthNames: LABELMONTH,
      },
      ranges: {
        'This Month': [firstDateOfMonth, today],
        'Last 30 Days': [last30, today],
        'Last Month': [lastMonth(1), lastDateOfLastMonth],
        'Last 2 Months': [lastMonth(2), lastDateOfLastMonth],
        'Custom Range': [today, today],
      },
      today,
      last7,
      last2,
      last30,
      firstDateOfMonth,
      lastDateOfMonth,
      dateRange: {
        startDate: last30,
        endDate: today,
      },
      apiAccessType: [],
      filteredAccessType: '',
      apiAccessTypeList: [
        {
          label: 'Sandbox',
          value: 'sandbox',
          onCheck: false,
        },
        {
          label: 'Live',
          value: 'production',
          onCheck: false,
        },
      ],
      listCollaborator: [],
      limit: 20,
      offset: 0,
      lastData: false,
      isWAValid: true,
    }
  },
  watch: {
    dateRange: {
      handler() {
        this.filterType = 'join_date'
        this.offset = 0
        this.limit = 20
        this.getListCollaborator()
      },
    },
  },
  mounted() {
    this.getListCollaborator()
  },
  methods: {
    showFilter() {
      this.isFilterShow = !this.isFilterShow
    },
    navigateToDetail(e) {
      this.$router.push({
        path: `/openapi/collaborator/detail/user=${btoa(e)}`,
      })
    },
    filterTicketByStatus(data) {
      const findIndexObj = this.apiAccessTypeList.findIndex(
        items => items.value === data.value,
      )
      const findObj = this.apiAccessType.findIndex(items => items === data.value)
      if (!this.apiAccessType.includes(data.value)) {
        if (this.apiAccessTypeList[findIndexObj].onCheck === true) {
          this.apiAccessType.push(data.value)
        }
      }

      if (this.apiAccessType.includes(data.value) && !this.apiAccessTypeList[findIndexObj].onCheck) {
        this.apiAccessType.splice(findObj, 1)
      }
      this.filteredAccessType = this.apiAccessType.join()
      this.filterType = 'api_access'
      this.offset = 0
      this.limit = 20
      this.getListCollaborator()
    },
    onChangeSearch: _.debounce(function search() {
      this.offset = 0
      this.limit = 20
      const trimmedSearch = this.search.trim()
      this.search = trimmedSearch

      if (trimmedSearch.length > 2) {
        this.getListCollaborator()
      } else if (trimmedSearch === '') {
        this.getListCollaborator()
      }
    }, 1000),
    handleScroll(event) {
      const { scrollTop, scrollHeight, clientHeight } = event.target
      if (scrollTop + clientHeight >= scrollHeight - 1 && !this.loading
      && !this.lastData) {
        this.limit += 20
        this.getListCollaboratorNext()
      }
    },
    handleImagePackage(value) {
      if (value === 'starter' || value === 'Starter') {
        return 'https://storage.googleapis.com/komerce/rajaongkir/Label-starter.svg'
      }
      if (value === 'pro' || value === 'Pro') {
        return 'https://storage.googleapis.com/komerce/rajaongkir/Label-pro.svg'
      }
      return 'https://storage.googleapis.com/komerce/rajaongkir/Label-enterprise.svg'
    },
    formatDateParams(d) {
      return moment(d)
        .format('YYYY-MM-DD')
    },
    async getListCollaborator() {
      const params = {
        limit: this.limit,
        offset: 0,
        api_access: this.filteredAccessType,
        start_date: `${this.formatDateParams(this.dateRange.startDate)} 00:00:00`,
        end_date: `${this.formatDateParams(this.dateRange.endDate)} 23:59:59`,
        search: this.search,
      }
      this.loading = true
      const scrollContainer = this.$refs['table-scroll'].$el
      const url = '/user/api/v1/admin/users'
      await openApiAxiosIns.get(url, { params })
        .then(res => {
          const { data } = res.data
          this.listCollaborator = data
          this.loading = false
          this.offset = 0
          this.limit = 20
          if (scrollContainer) {
            scrollContainer.scrollTop = 0
          }
          if (this.listCollaborator.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(() => {
          this.loading = false
          this.$toast_error({ message: 'Gagal Mengambil Data' })
        })
    },
    async getListCollaboratorNext() {
      if (!this.lastData) {
        const params = {
          limit: this.limit,
          offset: 0,
          api_access: this.filteredAccessType,
          start_date: `${this.formatDateParams(this.dateRange.startDate)} 00:00:00`,
          end_date: `${this.formatDateParams(this.dateRange.endDate)} 23:59:59`,
          search: this.search,
        }
        this.loading = true
        const url = '/user/api/v1/admin/users'
        await openApiAxiosIns.get(url, { params })
          .then(res => {
            const { data } = res.data
            this.listCollaborator = data
            this.loading = false
            if (this.listCollaborator.length < this.limit) {
              this.lastData = true
            } else {
              this.lastData = false
            }
          })
          .catch(() => {
            this.loading = false
            this.$toast_error({ message: 'Gagal Mengambil Data' })
          })
      }
    },
    checkWA(number) {
      if (number.startsWith('8')) {
        // eslint-disable-next-line no-param-reassign
        number = `62${number}`
      } else if (number.startsWith('0')) {
        // eslint-disable-next-line no-param-reassign
        number = number.replace(/^0/, '62')
      }
      const url = '/v1/check-wa'
      komshipAxiosIns
        .post(url, { phone_no: number })
        .then(res => {
          const { data } = res.data
          if (data === 'valid') {
            this.isWAValid = true
            this.$toast_success({ message: 'Phone Number Connected to Whatsapp' })
            window.open(`https://wa.me/${number}`, '_blank')
          } else {
            this.isWAValid = false
            this.$toast_error({ message: 'Phone Number not Connected to Whatsapp' })
          }
        })
        .catch(err => {
          this.isWAValid = false
          // eslint-disable-next-line no-console
          console.log(err)
          this.$toast_error({ message: 'Phone Number not Connected to Whatsapp' })
        })
    },
  },
}
</script>

<style scoped>
.sandbox {
  background-color: #dff3fe;
  color: #0aa6fb;
  font-weight: 500;
  font-size: 12px;
  border-radius: 4px;
  width: 100px;
}
.live {
  background-color: #dcf3eb;
  color: #4a9d78;
  font-weight: 500;
  font-size: 12px;
  border-radius: 4px;
  width: 100px;
}
.detail {
  color: #f95031;
  font-weight: 600;
}
</style>
