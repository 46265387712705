<template>
  <BCard>
    <div class="text-black text-[20px] mb-2 font-[600] d-flex align-items-center">
      <img
        src="https://storage.googleapis.com/komerce/assets/icons/back.svg"
        alt="Komerce"
        class="cursor-pointer mx-1"
        @click="$router.back()"
      >
      Detail Collaborator Open API
    </div>
    <BCard class="border">
      <div class="d-flex justify-content-between col-12 flex-lg-row flex-column gap-5">
        <div class="col-12 col-lg-4 rounded-lg">
          <img
            :src="detailCollaborator.photo_profile_url ? detailCollaborator.photo_profile_url : 'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg'"
            alt="photo"
            style="width: 250px; height: 250px"
            class="rounded-lg"
          >
        </div>
        <div class="col-12 col-lg-8">
          <div class="font-[600] d-flex justify-content-between">
            <span class="text-start text-black">
              Detail Data
            </span>
            <span class="text-end">
              <img
                v-if="detailCollaborator.package"
                :src="handleImagePackage(detailCollaborator.package)"
                alt="package"
              >
            </span>
          </div>
          <div class="d-flex justify-content-between mt-1">
            <span class="col-4 pl-0">Full Name/Company</span>
            <span class="col-8">: {{ detailCollaborator.name || '-' }}</span>
          </div>
          <div class="d-flex justify-content-between mt-1">
            <span class="col-4 pl-0">Email</span>
            <span class="col-8">: {{ detailCollaborator.email || '-' }}</span>
          </div>
          <div class="d-flex justify-content-between mt-1">
            <span class="col-4 pl-0">Phone Number</span>
            <span class="col-8 gap-2 d-flex align-items-center">: {{ detailCollaborator.phone_number || '-' }} <BButton
              class="p-0 justify-between items-center"
              type="button"
              aria-expanded="false"
              variant="light"
              @click="checkWA(detailCollaborator.phone_number)"
            >
              <img
                v-if="detailCollaborator.phone_number"
                src="https://storage.googleapis.com/komerce//whatsapp-ori.svg"
                alt="package"
                width="20px"
              >
            </BButton></span>
          </div>
          <div class="d-flex justify-content-between mt-1">
            <span class="col-4 pl-0">Address</span>
            <span class="col-8">: {{ detailCollaborator.address || '-' }}</span>
          </div>
          <div v-if="isEnterprise">
            <div class="d-flex justify-content-between mt-1">
              <span class="col-4 pl-0">Join Sandbox Date</span>
              <span
                v-if="detailCollaborator.join_sandbox_date"
                class="col-8"
              >: {{ DAY_MONTH_YEAR(detailCollaborator.join_sandbox_date) }}</span>
              <span
                v-else
                class="col-8"
              >: -</span>
            </div>
            <div class="d-flex justify-content-between mt-1">
              <span class="col-4 pl-0">Join Live Date</span>
              <span
                v-if="detailCollaborator.is_production"
                class="col-8"
              >: {{ DAY_MONTH_YEAR(detailCollaborator.join_production_date) }}
              </span>
              <span
                v-else
                class="col-8"
              >:
                <BButton
                  class="py-[3px] px-[10px]"
                  variant="primary"
                  @click="giveAccess()"
                >Give an Access</BButton>
              </span>
            </div>
          </div>
        </div>
      </div>
    </BCard>
    <AccessList :email-user="detailCollaborator.email" />
    <ModalGiveAccess :get-detail="getDetailCollaborator" />
  </BCard>
</template>
<script>
import {
  DAY_MONTH_YEAR,
} from '@/libs/filterDate'
import { openApiAxiosIns, komshipAxiosIns } from '@/libs/axios'
import ModalGiveAccess from './components/modal-give-access.vue'
import AccessList from './components/accessList.vue'

export default {
  components: {
    ModalGiveAccess,
    AccessList,
  },
  data() {
    return {
      DAY_MONTH_YEAR,
      email: '',
      detailCollaborator: [],
    }
  },
  computed: {
    isEnterprise() {
      return this.detailCollaborator.package === 'enterprise' || this.detailCollaborator.package === 'Enterprise' || this.detailCollaborator.package === ''
    },
  },
  mounted() {
    const { id } = this.$router.history.current.params
    const email = id.replace(/^user=/, '')
    this.email = atob(email)
    this.getDetailCollaborator()
  },
  methods: {
    giveAccess() {
      this.$bvModal.show('modal-give-access')
    },
    async getDetailCollaborator() {
      const url = '/user/api/v1/admin/users/detail'
      await openApiAxiosIns.get(`${url}/${this.email}`)
        .then(res => {
          const { data } = res.data
          this.detailCollaborator = data
        })
        .catch(() => {
          this.detailCollaborator = []
          this.$toast_error({ message: 'Gagal Mengambil Data' })
        })
    },
    handleImagePackage(value) {
      if (value === 'starter' || value === 'Starter') {
        return 'https://storage.googleapis.com/komerce/rajaongkir/Label-starter.svg'
      }
      if (value === 'pro' || value === 'Pro') {
        return 'https://storage.googleapis.com/komerce/rajaongkir/Label-pro.svg'
      } if (value === 'enterprise' || value === 'Enterprise') {
        return 'https://storage.googleapis.com/komerce/rajaongkir/Label-enterprise.svg'
      }
      return ''
    },
    checkWA(number) {
      if (number.startsWith('8')) {
        // eslint-disable-next-line no-param-reassign
        number = `62${number}`
      } else if (number.startsWith('0')) {
        // eslint-disable-next-line no-param-reassign
        number = number.replace(/^0/, '62')
      }
      const url = '/v1/check-wa'
      komshipAxiosIns
        .post(url, { phone_no: number })
        .then(res => {
          const { data } = res.data
          if (data === 'valid') {
            this.$toast_success({ message: 'Phone Number Connected to Whatsapp' })
            window.open(`https://wa.me/${number}`, '_blank')
          } else {
            this.$toast_error({ message: 'Phone Number not Connected to Whatsapp' })
          }
        })
        .catch(err => {
          this.isWAValid = false
          // eslint-disable-next-line no-console
          console.log(err)
          this.$toast_error({ message: 'Phone Number not Connected to Whatsapp' })
        })
    },
  },
}
</script>
